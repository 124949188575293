import React from "react";

import StaticLayout from "../components/StaticLayout";
import imgSrc1 from "../images/kaka.jpg";
import imgSrc2 from "../images/Spork.jpg";
import imgSrc3 from "../images/zwartgoude-jurk-1.jpg";
import imgSrc4 from "../images/zwartgoude-jurk.jpg";

const Page = () => (
  <StaticLayout isSingle>
    <main id="main" className="site-main" role="main">
      <div className="svg-container single-post-svg svg-block">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          version="1.1"
          viewBox="0 0 1890 150"
        >
          <g transform="translate(0,-902.36218)" />
          <path d="m 898.41609,-33.21176 0.01,0 -0.005,-0.009 -0.005,0.009 z" />
          <path d="m 898.41609,-33.21176 0.01,0 -0.005,-0.009 -0.005,0.009 z" />
          <path d="m 1925,0 0,150 -1925,0" />
        </svg>
      </div>
      <article
        id="post-32"
        className="post-32 page type-page status-publish hentry"
      >
        <header className="entry-header">
          <h1 className="entry-title">Galerij</h1>{" "}
        </header>
        {/* .entry-header */}
        <div className="entry-content">
          <p>
            <img
              className="alignnone size-medium wp-image-187"
              src={imgSrc1}
              alt=""
              width={300}
              height={199}
            />{" "}
            <img
              className="alignnone size-medium wp-image-176"
              src={imgSrc2}
              alt=""
              width={200}
              height={300}
            />
            <img
              className="alignnone size-medium wp-image-197"
              src={imgSrc3}
              alt=""
              width={200}
              height={300}
            />{" "}
            <img
              className="alignnone size-medium wp-image-198"
              src={imgSrc4}
              alt=""
              width={200}
              height={300}
            />
          </p>
          <p />
        </div>
        {/* .entry-content */}
        <footer className="entry-footer" />
        {/* .entry-footer */}
      </article>
      {/* #post-## */}
      <div className="svg-container single-post-svg single-svg-bottom svg-block">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          version="1.1"
          viewBox="0 0 1950 150"
        >
          <g transform="translate(0,-902.36218)" />
          <path d="m 898.41609,-33.21176 0.01,0 -0.005,-0.009 -0.005,0.009 z" />
          <path d="m 898.41609,-33.21176 0.01,0 -0.005,-0.009 -0.005,0.009 z" />
          <path d="M 0,150 0,0 1950,0" />
        </svg>
      </div>
    </main>
  </StaticLayout>
);

export default Page;
